<template>
	<div id="Reference">
		<!-- 报价蜘蛛 -->
		<router-view></router-view>
		<div class="ReferenceForm">
			<div class="title">新建报价单</div>
			<div class="ReferenceFormFun">
				<el-button icon="el-icon-plus" @click="toReferenceForm()">新建空白报价单</el-button>
			</div>
		</div>

		<div class="history" v-infinite-scroll="load" infinite-scroll-delay="500">
			<div class="title">历史报价单</div>
			<div class="list">
				<div class="item" v-for="(item,i) in QuotedPriceList" :key="i"
					@click="$router.push({name: 'history',query:{id:item.id}})">

					<div class="itemTit">
						<div class="tit">{{item.quotedPriceName}}</div>
						<div class="time">{{item.createTime}}</div>
					</div>

					<div class="itemInfo">
						<div class="price">价格：<span>¥{{ numberToCurrencyNo(item.price) }}</span></div>
						<div class="opts">
							<el-button class="change" @click.stop="changeItem(item)">修改</el-button>
							<el-button class="delete" @click.stop="deleteItem(item)">删除</el-button>
						</div>
					</div>
				</div>
				<p class="loading">
					<span v-if="loadingType == 'show'">触底加载</span>
					<span v-if="loadingType == 'loading'"><i class="el-icon-loading"></i>加载中...</span>
					<span v-if="loadingType == 'none'">没有更多数据</span>
				</p>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		quotedPriceList, // 获取报价列表
		// mechanismList // 机构列表查询
		quotedPriceRemove // 删除报价单
	} from '@/request/apiReference.js';
	export default {
		name: 'Reference',
		components: {},
		data() {
			return {
				QuotedPriceList: [],
				pageSize: 9,
				pageNum: 1,
				loadingType: 'show'
			}
		},
		created() {
			this.getQuotedPriceList();
		},
		mounted() {},
		methods: {
			// 修改报价单
			changeItem(item) {
				console.log(item, '修改报价单');
				this.$router.push({
					name: 'ReferenceForm',
					query: {
						id: item.id
					}
				})
			},

			// 删除报价单
			deleteItem(item) {
				this.$confirm('此操作将删除该报价单, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log(item, '删除报价单');
					quotedPriceRemove(item.id).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '提示',
								message: res.msg,
								position: 'bottom-right',
								type: 'success'
							});
							// this.QuotedPriceList = [];
							// this.pageNum = 1;
						} else {
							this.$notify({
								title: '提示',
								message: res.msg,
								position: 'bottom-right',
								type: 'error'
							});
						}
						// this.getQuotedPriceList();
						this.$router.go(0)
					})
				})
			},

			load() {
				this.getQuotedPriceList();
			},

			// 获取报价列表
			getQuotedPriceList() {
				if (this.loadingType == 'show') {
					this.loadingType = 'loading';
					let data = {};
					data.pageSize = this.pageSize;
					data.pageNum = this.pageNum;
					quotedPriceList(data).then(res => {
						if (res.code == 200) {
							let data = res.rows
							if (data[0]) {
								this.pageNum++;
								this.loadingType = 'show';
								this.QuotedPriceList = [...this.QuotedPriceList, ...data]
							} else {
								this.loadingType = 'none';
							}
						}
					})
				}
			},

			// 新建空白报价单
			toReferenceForm() {
				this.$router.push({
					name: 'ReferenceForm'
				})

			},


			// 千位符
			numberToCurrencyNo(value) {
				if (!value) return 0
				// 获取整数部分
				const intPart = Math.trunc(value)
				// 整数部分处理，增加,
				const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
				// 预定义小数部分
				let floatPart = ''
				// 将数值截取为小数部分和整数部分
				const valueArray = value.toString().split('.')
				if (valueArray.length === 2) { // 有小数部分
					floatPart = valueArray[1].toString() // 取得小数部分
					return intPartFormat + '.' + floatPart
				}
				return intPartFormat + floatPart
			}
		}
	}
</script>

<style lang="less" scoped>
	#Reference {
		padding: 50px;
		background-color: #f2f2f2;
		min-height: 100vh;

		.ReferenceForm {
			margin-bottom: 40px;

			.title {
				font-size: 28px;
				line-height: 3;
				color: #181B1A;
			}

			.ReferenceFormFun {
				background-color: #fff;
				padding: 40px;
				border-radius: 20px;

				.el-button {
					display: block;
					width: 100%;
					font-size: 24px;
					height: 60px;
					border-radius: 5px;
				}
			}
		}

		.history {
			overflow: hidden;

			.title {
				font-size: 28px;
				line-height: 3;
				color: #181B1A;
			}

			.loading {
				text-align: center;
				font-size: 14px;
			}

			.list {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: wrap;
				background-color: #fff;
				padding: 40px;
				border-radius: 20px;

				.item {
					border: 1px solid #E1E1E1;
					width: 100%;
					margin-bottom: 24px;
					border-radius: 5px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					padding: 20px;
					cursor: pointer;
					transition: all .3s;

					* {
						cursor: pointer;
						transition: all .3s;
					}

					.itemTit {
						flex: 1;

						.tit {
							width: 500px;
							font-size: 14px;
							font-weight: 500;
							color: #181B1A;
							overflow: auto;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.time {
							font-size: 12px;
							font-weight: 400;
							color: rgba(24, 27, 26, 0.6);
						}
					}

					.itemInfo {
						display: flex;
						align-items: center;

						.price {
							font-size: 12px;
							color: rgba(24, 27, 26, 0.6);
							padding: 0 0 0 40px;

							span {
								font-size: 16px;
								font-family: PingFang SC-Medium, PingFang SC;
								font-weight: 500;
								color: #181B1A;
							}
						}

						.opts {
							display: flex;
							padding: 0 0 0 40px;
							width: 0;
							overflow: hidden;

							.change {
								background-color: #F4F4F4;
								font-size: 14px;
								color: #181B1A;
								border: none;
							}

							.delete {
								background-color: #682EDC;
								font-size: 14px;
								color: #fff;
								border: none;
							}
						}
					}

					&:hover {
						border: 1px solid #682EDC;

						.opts {
							width: 200px;
						}
					}
				}
			}
		}

	}
</style>